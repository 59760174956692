import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const jobList = [
  { id: "Job1", title: "Dept Banking - Financial Examiner VIII: Review Examiner" },
  { id: "Job2", title: "Housing Advisor" },
  { id: "Job3", title: "Optometric Technician" },
  // Add more jobs here as needed
];

const jobsPerPage = 3;

function VeteranJobs() {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(jobList.length / jobsPerPage);
  const startIndex = (currentPage - 1) * jobsPerPage;
  const currentJobs = jobList.slice(startIndex, startIndex + jobsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container">
      <h1>Veteran Jobs</h1>
      <p>
  Veteran jobs are offered by preferred employers who value the skills, discipline, and experience veterans bring to the workforce. 
  These employers provide opportunities in various fields, supporting the transition from military to civilian careers. 
  With a commitment to hiring veterans, they help ensure veterans continue to succeed and thrive in their post-service careers.
      </p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Job Title</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {currentJobs.map((job, index) => (
            <tr key={job.id}>
              <td>{startIndex + index + 1}</td>
              <td>{job.title}</td>
              <td>
                <Link to={`/VeteranJobs/${job.id}`}>View Job</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between align-items-center">
        <p>
          Showing {startIndex + 1} to {startIndex + currentJobs.length} of {jobList.length} jobs
        </p>
        <div>
          <button 
            onClick={handlePreviousPage} 
            className="btn btn-primary" 
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button 
            onClick={handleNextPage} 
            className="btn btn-primary" 
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default VeteranJobs;
