import React from 'react';
import { useParams } from 'react-router-dom';

function FormPage() {
  const { formName } = useParams();

  // Map generic form names to actual form data
  const formMap = {
    "Form1": "10-10EZ",
    "Form2": "21-526EZ",
    "Form3": "28-1900"
  };

  const actualFormName = formMap[formName] || formName;

  // Hard coded form details based on actual form name
  const formDetails = {
    "10-10EZ": {
      title: "Application for Health Benefits",
      type: "Health Care Form",
      vaAdministration: "Veterans Health Administration",
      formUrl: "https://www.va.gov/vaforms/medical/pdf/10-10EZ-fillable.pdf",
      usageDescription: "This form is used by veterans to apply for health care benefits.",
      videoUrl: "https://www.youtube.com/embed/SozVGoNzBqY" // Actual YouTube video link
    },
    "21-526EZ": {
      title: "Application for Disability Compensation and Related Compensation Benefits",
      type: "Disability Compensation Form",
      vaAdministration: "Veterans Benefits Administration",
      formUrl: "https://www.va.gov/vaforms/va/pdf/21-526EZ.pdf",
      usageDescription: "This form is used by veterans to apply for disability compensation.",
      videoUrl: "https://www.youtube.com/embed/773i874OFVA" // Actual YouTube video link
    },
    "28-1900": {
      title: "Disabled Veterans Application for Vocational Rehabilitation",
      type: "Vocational Rehabilitation Form",
      vaAdministration: "Veterans Benefits Administration",
      formUrl: "https://www.va.gov/vaforms/va/pdf/28-1900.pdf",
      usageDescription: "This form is used by disabled veterans to apply for vocational rehabilitation.",
      videoUrl: "https://www.youtube.com/embed/5deKCK0-w1Y" // Actual YouTube video link
    }
  };

  const formInfo = formDetails[actualFormName] || {};

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{actualFormName}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{formInfo.title}</h6>
          <p className="card-text"><strong>Type:</strong> {formInfo.type}</p>
          <p className="card-text"><strong>VA Administration:</strong> {formInfo.vaAdministration}</p>
          <p className="card-text"><strong>Form URL:</strong> <a href={formInfo.formUrl} target="_blank" rel="noopener noreferrer">{formInfo.formUrl}</a></p>
          <p className="card-text"><strong>Usage Description:</strong> {formInfo.usageDescription}</p>
          
          {formInfo.videoUrl && (
            <div className="mt-4">
              <h5>How to Fill Out This Form:</h5>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe 
                  className="embed-responsive-item" 
                  src={formInfo.videoUrl} 
                  title={`How to fill out ${actualFormName}`} 
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormPage;
