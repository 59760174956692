import React, { useState, useEffect } from 'react';
import './HomePage.css';
function HomePage() {
  const [images, setImages] = useState([]);
  const[facilityNames, setFacilityNames] = useState([]);
  useEffect(() => {
    // Example image URLs
    const imageUrls = [
      'https://www.va.gov/img/styles/3_2_medium_thumbnail/public/2021-04/AustinOPC.jpg',
      'https://texvet.org/sites/default/files/old-2021/files/almdhospital.jpg',
      'https://www.va.gov/img/styles/3_2_medium_thumbnail/public/2021-08/San%20Diego%20VA%20Medical%20Center.jpg'
    ];
    const names = ['Austin VA Clinic', 'South Texas VA Medical Center', 'VA San Diego Medical Center']
    setImages(imageUrls);
    setFacilityNames(names);
  }, []);

  return (
    <div className="homepage-container">
      <h1 className="homepage-title">VetAssist</h1>
      <p className="homepage-description">
        Veterans in Texas are currently facing numerous challenges, including access to healthcare, education, and employment opportunities. Our mission is to provide resources and support to help veterans overcome these obstacles and improve their quality of life.
      </p>
      <p className="homepage-statistic">
        According to recent statistics, approximately 25% of veterans are unemployed compared to 21% for non-veterans. This highlights the urgent need for veteran job aid and resources for our veterans.
      </p>
      <div className="image-gallery">
        {images.map((url, index) => (
          <div key={index} className="image-container">
            <img src={url} alt={facilityNames[index]} className="facility-image" />
            <p className="image-label">{facilityNames[index]}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
