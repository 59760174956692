import React from 'react';
import { useParams } from 'react-router-dom';

function JobPage() {
  const { jobName } = useParams();

  // Map generic names to actual job titles
  const jobMap = {
    "Job1": "Dept Banking - Financial Examiner VIII: Review Examiner",
    "Job2": "Housing Advisor",
    "Job3": "Optometric Technician"
  };

  const actualJobName = jobMap[jobName] || jobName;

  // Hard coded job details based on actual job title
  const jobDetails = {
    "Dept Banking - Financial Examiner VIII: Review Examiner": {
      companyName: "DEPARTMENT OF BANKING STATE OF TEXAS",
      jobRole: "Financial Examiner",
      location: "AUSTIN, TX",
      pay: "$13,334 -> $15,312/Month",
      benefits: "Health insurance, 401k, Hybrid Work",
      applyLink: "https://www.workintexas.com/vosnet/jobbanks/jobdetails.aspx?enc=9B8/uT7EfbEIDLIMZ8rhowzqyZjsNU1dBVrIZPNl61aLdeYEgg50x36hjNUp+PGjKAWa8+kXvIYIxPHj+A2T433gnSMR93gEFlwNgsCJhSV7vLWvhUdQdJ9aw+Q8dQXvk/iH0UDET3FnaDxz4S1NmO+bZcDTbJ5tFVP4BMFcY85bHn00d4gJDQRaif57x6hfBP+UvaZjQLqY271NpHYnpdhCSv6JMpJed/G7KI+0ucCZVi1sc9DhUEB9NOFjg7eZZlvyJEXoUt0n34ZHnZM4Q+vq9zTbD7oqRtBXtYU16EnjbsjApku5Nyu7RY60EbM3NL4xWi4Vm9w1atlqjPqjV1gCxWvonzqGGk+PsBPu3KmwZUM3iLtpon49U3IOLRDVUmS7QH+YJoQbytVNHytp187srEKIV91wWqj5KdvuzT/CjZ0QmTwtGnF47Fvc8ERd66I8+Lhe7eySenELvvgsNNTYSQrYjkVtZPHCiyfssf9ZQMlv9vL4CPaWvTENIE5cwPGq3GAU0BgU0gh+4yk+vrf1GLgBJGVOo0aAeSKWqFDMoLrk0Lrxx01w7e9mpptVhw6jyVClaGXE9pUnUGwXSmv1vRyX930z9/vfmc8rWM62PRk+QVhSA+Oo/9pXsRlSLfEo9dQBAhvLjS47LeupOuh/b3TieXzQRL3OiSo8uaVncmNUpsSY0swaX3J9ZVSoTQ11mcbVoIEEPH9UWk8+dA=="
    },
    "Housing Advisor": {
      companyName: "UNIVERSITY OF TEXAS AT AUSTIN",
      jobRole: "Secretaries and Administrative Assistant",
      location: "AUSTIN,TX",
      pay: "$32k - $51k/year",
      benefits: "Health insurance, 401k, Paid time off",
      applyLink: "https://www.workintexas.com/vosnet/jobbanks/jobdetails.aspx?enc=9B8/uT7EfbEIDLIMZ8rhowzqyZjsNU1dBVrIZPNl61aLdeYEgg50x36hjNUp+PGj429S/dyPCykE36FonByjbwCj9DWy96cDawaELkGMZqU0lja5HDqBq1gIX9S9k7eXMh60XWeRoGtK1rypBABNkW2XkEiMqg7T8cuLqGmEzY2hn3VNTQk3GvYLO0GLqftMRrW9Nrc0Krq0bEt64800jAGxQSF5PUF6V9SKLVUfjLTpjfxZWuQUGxtJ9g+j5PE2qi0XjYptISInjDKXwJRHOvCIwiO7qL3pdls/fue2ZIL651d1P6HfmKXa1WZydedIGKemlVdlhC422JOsUQt/CA=="
    },
    "Optometric Technician": {
      companyName: "AEG TEXAS PROFESSIONAL",
      jobRole: "Optometrist",
      location: "HOUSTON, TX",
      pay: "$33k - $37k/year",
      benefits: "Health insurance, Dental Insurance, Paid time off",
      applyLink: "https://www.workintexas.com/vosnet/jobbanks/jobdetails.aspx?enc=9B8/uT7EfbEIDLIMZ8rhowzqyZjsNU1dBVrIZPNl61aLdeYEgg50x36hjNUp+PGjWQ5mnq/GFPG5PSdVzMSpJTN11oKL0nML21ElwbSDCsEKsAy3xMFmzFbYLAbL5+yA/iKC4Pke/Qrt28TdflVfrYHdfK4JZuhRU2yPN/3mBrVDv7zYppollxZREb5dPYifnjcvfrxcdK4W6hADqNL2/IvjO69zMZ44Ii5++H64KbLD7B80R2/zLrLeHPk+jDJ3Y5fIhBg3VBvAZfDunP+h4pefzKdYKlXOKBnf2AKm0StHMLt7PsrPeA4IMS3yhivEqSS0bTlDB2h2UXO48EqwXZVh8JvWtlKdhYe3Fsfs2GVILP30oFILGGHMucL4y2vv6LZpGstUpOMjI4Z6MZrnPvL01rCmAz15o5YJCgXmnivVBSrZU+31lyR2D9goj4Hqh3AOgv7z3ztNYrF3UPNuPK63iRJcAnkjtO6QXqPqAucsmTQ7wYFo3UlNinc+l0HU4b3Js3tpqNbRxMYfKl3ygG98L22O3M3hYyywPETD4N71EeL1opL5mEF0QAKl2l70"
    }
  };

  const jobInfo = jobDetails[actualJobName] || {};

  return (
    <div className="container mt-4">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{actualJobName}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{jobInfo.companyName}</h6>
        <p className="card-text"><strong>Role:</strong> {jobInfo.jobRole}</p>
        <p className="card-text"><strong>Location:</strong> {jobInfo.location}</p>
        <p className="card-text"><strong>Pay:</strong> {jobInfo.pay}</p>
        <p className="card-text"><strong>Benefits:</strong> {jobInfo.benefits}</p>
        <a href={jobInfo.applyLink} className="card-link btn btn-primary" target="_blank" rel="noopener noreferrer">Apply Now</a>
        </div>
    </div>
  </div>
  );
}

export default JobPage;
