import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const facilityList = [
  { id: "Facility1", name: "Austin VA Clinic" },
  { id: "Facility2", name: "Fort Sam Houston National Cemetery" },
  { id: "Facility3", name: "Dallas Vet Center" },
  // Add more facilities here as needed
];

const facilitiesPerPage = 3;

function VeteranFacilities() {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(facilityList.length / facilitiesPerPage);
  const startIndex = (currentPage - 1) * facilitiesPerPage;
  const currentFacilities = facilityList.slice(startIndex, startIndex + facilitiesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container">
      <h1>Veteran Facilities</h1>
      <p>
  Veteran facilities, including VA health care centers, cemeteries, and benefit offices, provide critical services and support to veterans 
  and their families. These facilities offer medical care, mental health services, burial honors, and assistance with benefits and claims. 
  They are designed to ensure veterans receive the care and recognition they deserve for their service.
      </p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Facility Name</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {currentFacilities.map((facility, index) => (
            <tr key={facility.id}>
              <td>{startIndex + index + 1}</td>
              <td>{facility.name}</td>
              <td>
                <Link to={`/VeteranFacilities/${facility.id}`}>View Facility</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between align-items-center">
        <p>
          Showing {startIndex + 1} to {startIndex + currentFacilities.length} of {facilityList.length} facilities
        </p>
        <div>
          <button 
            onClick={handlePreviousPage} 
            className="btn btn-primary" 
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button 
            onClick={handleNextPage} 
            className="btn btn-primary" 
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default VeteranFacilities;
