import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const formsList = [
  { id: "Form1", name: "10-10EZ - Application for Health Benefits" },
  { id: "Form2", name: "21-526EZ - Application for Disability Compensation" },
  { id: "Form3", name: "28-1900 - Vocational Rehabilitation Form" },
  // Add more forms here as needed
];

const formsPerPage = 3;

function VeteranForms() {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(formsList.length / formsPerPage);
  const startIndex = (currentPage - 1) * formsPerPage;
  const currentForms = formsList.slice(startIndex, startIndex + formsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container">
      <h1>Veteran Forms</h1>
      <p>
  Veteran forms are essential documents required for veterans and their families to apply for various benefits and services, 
  including health care, disability compensation, education assistance, vocational rehabilitation, and burial benefits. 
  These forms help the Department of Veterans Affairs (VA) assess and process claims to ensure veterans receive the benefits 
  they have earned through their service.
      </p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Form Name</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {currentForms.map((form, index) => (
            <tr key={form.id}>
              <td>{startIndex + index + 1}</td>
              <td>{form.name}</td>
              <td>
                <Link to={`/VeteranForms/${form.id}`}>View Form</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between align-items-center">
        <p>
          Showing {startIndex + 1} to {startIndex + currentForms.length} of {formsList.length} forms
        </p>
        <div>
          <button 
            onClick={handlePreviousPage} 
            className="btn btn-primary" 
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button 
            onClick={handleNextPage} 
            className="btn btn-primary" 
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default VeteranForms;
