import React from 'react';
import { useParams } from 'react-router-dom';

function FacilityPage() {
  const { facilityName } = useParams();

  // Map facilityName to actual facility data
  const facilityMap = {
    "Facility1": "Austin VA Clinic",
    "Facility2": "Fort Sam Houston National Cemetery",
    "Facility3": "Dallas Vet Center"
  };

  const actualFacilityName = facilityMap[facilityName] || facilityName;

  // Hard coded facility details based on actual facility name
  const facilityDetails = {
    "Austin VA Clinic": {
      facilityType: "VA Health",
      website: "https://www.va.gov/central-texas-health-care/locations/austin-va-clinic/",
      address: "7901 Metropolis Dr, Austin, TX 78744",
      phoneNumber: "(800) 423-2111"
    },
    "Fort Sam Houston National Cemetery": {
      facilityType: "VA Cemeteries",
      website: "https://www.cem.va.gov/cems/nchp/ftsamhouston.asp",
      address: "1520 Harry Wurzbach Rd, San Antonio, TX 78209",
      phoneNumber: "(210) 820-3891"
    },
    "Dallas Vet Center": {
      facilityType: "Vet Center",
      website: "https://www.va.gov/dallas-vet-center/",
      address: "8610 Greenville Avenue Suite 125, Dallas, TX 75243",
      phoneNumber: "(214) 361-5896"
    }
  };

  const facilityInfo = facilityDetails[actualFacilityName] || {};

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{actualFacilityName}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{facilityInfo.facilityType}</h6>
          <p className="card-text"><strong>Website:</strong> <a href={facilityInfo.website} target="_blank" rel="noopener noreferrer">{facilityInfo.website}</a></p>
          <p className="card-text"><strong>Address:</strong> {facilityInfo.address}</p>
          <p className="card-text"><strong>Phone Number:</strong> {facilityInfo.phoneNumber}</p>
        </div>
      </div>
    </div>
  );
}

export default FacilityPage;
